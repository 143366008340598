@import "./abstracts/variables";

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %headings !optional;
}

@media (min-width: 577px) {

    .log-wrapper {
        border-radius: 2.5rem 2.5rem 0 0;

        %headings {
            margin-left: .5rem;
        }
    }

    .content-wrapper {
        border-radius: 1.5rem 1.5rem 0 0;
        padding-top: .75rem;
    }

}